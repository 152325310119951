import { Button } from '@saladtechnologies/garden-components'
import classnames from 'classnames'
import type { ChangeEvent, FormEventHandler, FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Referrals from '../assets/Referrals.svg'
import { selectReferralCodeErrorMessage, selectReferralCodeSubmitLoading } from '../features/apiSelectors'
import {
  executeUpdateViewedReferralOnboardingAndRedirect,
  setReferralCode,
  setReferralCodeErrorMessage,
} from '../features/apiSlice'
import { useAppDispatch, useAppSelector } from '../features/hook'
import { OnboardingEnterReferralCodePageMessages } from '../messages/OnboardingEnterReferralCodePageMessages'

interface OnboardingEnterReferralCodePageProps {
  onSkip: () => void
  onSubmit: (referralCode: string) => void
  submitLoading: boolean
  errorMessage?: string
}

export const ConnectedOnboardingEnterReferralCodePage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const referralCodeSubmitLoading = useAppSelector(selectReferralCodeSubmitLoading)
  const referralCodeErrorMessage = useAppSelector(selectReferralCodeErrorMessage)
  const onSubmit = useCallback(
    (referralCode: string) => {
      dispatch(setReferralCode({ code: referralCode.trim(), track: true }))
    },
    [dispatch],
  )

  return (
    <OnboardingEnterReferralCodePage
      onSkip={() => {
        dispatch(executeUpdateViewedReferralOnboardingAndRedirect())
      }}
      submitLoading={referralCodeSubmitLoading}
      onSubmit={onSubmit}
      errorMessage={referralCodeErrorMessage}
    />
  )
}

export const OnboardingEnterReferralCodePage: FunctionComponent<OnboardingEnterReferralCodePageProps> = ({
  onSkip,
  submitLoading,
  onSubmit,
  errorMessage,
}) => {
  const [referralCode, setCode] = useState('')
  const intl = useIntl()

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(referralCode.trim())
    },
    [onSubmit, referralCode],
  )

  const handleSkip: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onSkip()
    },
    [onSkip],
  )

  useEffect(() => {
    setReferralCodeErrorMessage(undefined)
  }, [])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }, [])

  return (
    <div className="is-flex">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third pt-6">
            <form>
              <div className="container mx-2 pt-6">
                <h1 className="title pt-6 is-1">
                  {intl.formatMessage(OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageTitle)}
                </h1>
                <div>
                  <p className="subtitle is-6 pt-2 has-text-primary">
                    {intl.formatMessage(
                      OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePagePrimaryDescription,
                    )}
                  </p>
                  <div className="field pt-2">
                    <label className="label mb-0 has-text-primary" htmlFor="referral-code-input">
                      {intl.formatMessage(
                        OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageInputTitle,
                      )}
                    </label>

                    <div className={classnames('control has-icons-left', { 'has-icons-right': errorMessage })}>
                      <input
                        className={classnames('input', { 'is-danger': errorMessage, 'is-success': !errorMessage })}
                        id="referral-code-input"
                        type="text"
                        placeholder={intl.formatMessage(
                          OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageInputPlaceHolder,
                        )}
                        value={referralCode}
                        onChange={handleInputChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-key" />
                      </span>
                      {errorMessage ? (
                        <span className="icon is-small is-right">
                          <i className="fa fa-exclamation-triangle" />
                        </span>
                      ) : null}
                      <p
                        className={classnames('mt-1 p-1 has-text-primary help', {
                          'has-background-danger is-danger': errorMessage,
                        })}
                      >
                        {errorMessage ?? null} &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className="field is-grouped pt-2">
                    <div className="control">
                      <Button
                        type="submit"
                        variant="secondary"
                        isLoading={submitLoading}
                        onClick={handleSubmit as () => void}
                        label={intl.formatMessage(
                          OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageSubmitButtonLabel,
                        )}
                      />
                    </div>
                    <div className="control">
                      <Button
                        variant="outlined"
                        outlineColor="#B2D530"
                        onClick={handleSkip as () => void}
                        label={intl.formatMessage(
                          OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageSkipButtonLabel,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-one-half is-flex is-justify-content-right is-hidden-mobile">
            <img
              src={Referrals}
              alt={intl.formatMessage(
                OnboardingEnterReferralCodePageMessages.OnboardingEnterReferralCodePageImageAltText,
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
