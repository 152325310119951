import type { FunctionComponent } from 'react'
import { selectPage } from './features/apiSelectors'
import { useAppSelector } from './features/hook'
import { LoginPageEnum } from './models'
import {
  ConnectedEnterCodePage,
  ConnectedEnterEmailPage,
  ConnectedOnboardingEnterReferralCodePage,
  ConnectingToSaladPage,
  FetchingIngredientsPage,
  LoginCompletedPage,
  StillConnectingToSaladPage,
} from './pages'

interface LoginContainerProps {}

export const LoginContainer: FunctionComponent<LoginContainerProps> = () => {
  const currentPage = useAppSelector(selectPage)

  switch (currentPage) {
    case LoginPageEnum.ENTER_EMAIL:
      return <ConnectedEnterEmailPage />
    case LoginPageEnum.ENTER_CODE:
      return <ConnectedEnterCodePage />
    case LoginPageEnum.FETCHING_INGREDIENTS:
      return <FetchingIngredientsPage />
    case LoginPageEnum.ONBOARDING_ENTER_REFERRAL_CODE:
      return <ConnectedOnboardingEnterReferralCodePage />
    case LoginPageEnum.CONNECTING_TO_SALAD:
      return <ConnectingToSaladPage />
    case LoginPageEnum.STILL_CONNECTING_TO_SALAD:
      return <StillConnectingToSaladPage />
    case LoginPageEnum.LOGIN_COMPLETED:
      return <LoginCompletedPage />
    default:
      return null
  }
}
