import { defineMessages } from 'react-intl'

export const EnterEmailPageMessages = defineMessages({
  enterEmailPageMainDescription: {
    id: 'login.enterEmailPage.mainDescription',
    defaultMessage: `Rewards inbound! Create a Salad account to earn from your idle PC. If you're returning to the Kitchen, enter the email you used to sign up.`,
  },
  enterEmailPageWaitingForInstall: {
    id: 'login.enterEmailPage.enterEmailPageWaitingForInstall',
    defaultMessage: `Waiting for install? Sign-up for Salad now!`,
  },
  enterEmailPageInputTitle: {
    id: 'login.enterEmailPage.inputTitle',
    defaultMessage: 'Email',
  },
  enterEmailPageInputPlaceHolder: {
    id: 'login.enterEmailPage.inputPlaceHolder',
    defaultMessage: 'Enter Your Email Address',
  },
  enterEmailPageSubmitButtonLabel: {
    id: 'login.enterEmailPage.submitButtonLabel',
    defaultMessage: 'Submit',
  },
  enterEmailPageSecondaryDescription: {
    id: 'login.enterEmailPage.secondaryDescription',
    defaultMessage: `Enter your email address to receive a one-time login code.`,
  },
  enterEmailPageThirdDescription: {
    id: 'login.enterEmailPage.thirdDescription',
    defaultMessage: `Salad is optimized for use with the following browsers: Google Chrome, Mozilla Firefox, Opera, and Microsoft Edge. Known compatibility issues may prevent you from logging in with Brave.`,
  },
  enterEmailPageImageAltText: {
    id: 'login.enterEmailPage.imageAltText',
    defaultMessage: 'Rewards',
  },
  enterEmailPageTermsAndConditions: {
    id: 'login.enterEmailPage.TermsAndConditions',
    defaultMessage: 'I agree to the {TermsAndConditionsLink} and {PrivacyPolicyLink}',
  },
  enterEmailPageTermsOfService: {
    id: 'login.enterEmailPage.termsAndConditionsCopy',
    defaultMessage: 'Terms of Service',
  },
  enterEmailPagePrivacyPolicy: {
    id: 'login.enterEmailPage.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  enterEmailPageAlternativeLoginText: {
    id: 'login.enterEmailPage.enterEmailPageAlternativeLoginText',
    defaultMessage: 'Or use...',
  },
  googleSignInButton: {
    id: 'login.enterEmailPage.googleSignInButton',
    defaultMessage: 'Sign in with Google',
  },
  passkeySignInButton: {
    id: 'login.enterEmailPage.passkeySignInButton',
    defaultMessage: 'Login with Passkey',
  },
  passkeyAuthenticationFailure: {
    id: 'login.enterEmailPage.passkeyAuthenticationFailure',
    defaultMessage: 'Passkey authentication failure, please try again',
  },
  passkeySignInButtonImageAltText: {
    id: 'login.downloadStartedPage.passkeySignInButtonImageAltText',
    defaultMessage: 'Login key',
  },
})
