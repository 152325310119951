import { Button } from '@saladtechnologies/garden-components'
import classnames from 'classnames'
import type { ChangeEvent, FormEventHandler, FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Key from '../assets/Key.svg'
import LoginScreenRewards from '../assets/LoginScreenRewards.png'
import SecondaryLockup from '../assets/SecondaryLockup.png'
import { isPasskeyFeatureEnabled } from '../config'
import { getIsPasskeySupported } from '../epics/utils'
import {
  selectIsPasskeyLoginFailed,
  selectLoginEmailErrorMessage,
  selectLoginEmailSubmitLoading,
} from '../features/apiSelectors'
import { loginWithPasskey, setLoginEmail } from '../features/apiSlice'
import { useAppDispatch, useAppSelector } from '../features/hook'
import { selectWithDownloadReferrer } from '../features/startupSelectors'
import { EnterEmailPageMessages, ErrorMessages } from '../messages'
import { GoogleSignInForm } from './components'

interface EnterEmailPageProps {
  errorMessage?: string
  isPasskeyLoginFailed: boolean
  onSubmit: (email: string) => void
  onLoginWithPasskey: () => void
  submitLoading: boolean
}

export const ConnectedEnterEmailPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const loginEmailSubmitLoading = useAppSelector(selectLoginEmailSubmitLoading)
  const loginEmailErrorMessage = useAppSelector(selectLoginEmailErrorMessage)
  const isPasskeyLoginFailed = useAppSelector(selectIsPasskeyLoginFailed)

  const onSubmit = useCallback(
    (email: string) => {
      dispatch(setLoginEmail(email.trim()))
    },
    [dispatch],
  )
  const onLoginWithPasskey = () => {
    dispatch({
      type: loginWithPasskey.type,
      meta: { ipcSource: 'renderer/app' },
    })
  }

  return (
    <EnterEmailPage
      submitLoading={loginEmailSubmitLoading}
      errorMessage={loginEmailErrorMessage}
      isPasskeyLoginFailed={isPasskeyLoginFailed}
      onSubmit={onSubmit}
      onLoginWithPasskey={onLoginWithPasskey}
    />
  )
}

export const EnterEmailPage: FunctionComponent<EnterEmailPageProps> = ({
  submitLoading,
  errorMessage,
  isPasskeyLoginFailed,
  onSubmit,
  onLoginWithPasskey,
}) => {
  const [checked, setChecked] = useState(false)
  const [isPasskeySupported, setIsPasskeySupported] = useState(true)
  const [email, setEmail] = useState('')
  const intl = useIntl()
  const withDownloadReferrer = useSelector(selectWithDownloadReferrer)

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(email.trim())
    },
    [email, onSubmit],
  )

  const handleCheckedChange = useCallback((checked: boolean) => {
    setChecked(!checked)
  }, [])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }, [])

  useEffect(() => {
    const checkIsPasskeySupported = async () => {
      try {
        const isPasskeySupported = await getIsPasskeySupported()
        setIsPasskeySupported(isPasskeySupported)
      } catch (error) {
        setIsPasskeySupported(false)
      }
    }

    checkIsPasskeySupported()
  }, [])

  return (
    <div className="is-flex">
      <div className="container">
        <div className="columns my-0">
          <div className="column is-half pt-6 mx-2">
            <form onSubmit={handleSubmit}>
              <div className="container pt-6">
                <figure className="has-text-left">
                  <img src={SecondaryLockup} alt="Salad" />
                </figure>
                {withDownloadReferrer ? (
                  <h1 className="title title-size pt-6">
                    {intl.formatMessage(EnterEmailPageMessages.enterEmailPageWaitingForInstall)}
                  </h1>
                ) : (
                  <div className="column is-three-quarters p-0 pt-6">
                    <p className="has-text-left subtitle is-6 pt-6 has-text-primary">
                      {intl.formatMessage(EnterEmailPageMessages.enterEmailPageMainDescription)}
                    </p>
                  </div>
                )}
              </div>
              <div className="column is-three-quarters p-0 pt-6">
                <div className="field has-text-left has-text-primary pb-3">
                  <div className="control">
                    <label className="is-clickable">
                      <input
                        className="is-clickable mr-1"
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleCheckedChange(checked)}
                      />
                      <FormattedMessage
                        {...EnterEmailPageMessages.enterEmailPageTermsAndConditions}
                        values={{
                          TermsAndConditionsLink: (
                            <a id="SaladTermsAndConditionsLink" href="https://salad.com/terms">
                              <u className="has-text-primary">
                                {intl.formatMessage(EnterEmailPageMessages.enterEmailPageTermsOfService)}
                              </u>
                            </a>
                          ),
                          PrivacyPolicyLink: (
                            <a id="SaladPrivacyPolicyLink" href="https://salad.com/privacy">
                              <u className="has-text-primary">
                                {intl.formatMessage(EnterEmailPageMessages.enterEmailPagePrivacyPolicy)}
                              </u>
                            </a>
                          ),
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="field">
                  <label className="label mb-0 has-text-primary" htmlFor="email-input">
                    {intl.formatMessage(EnterEmailPageMessages.enterEmailPageInputTitle)}
                  </label>
                  <div className={classnames('control has-icons-left', { 'has-icons-right': errorMessage })}>
                    <input
                      className={classnames('input', { 'is-danger': errorMessage, 'is-success': !errorMessage })}
                      id="email-input"
                      type="email"
                      placeholder={intl.formatMessage(EnterEmailPageMessages.enterEmailPageInputPlaceHolder)}
                      value={email}
                      onChange={handleInputChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa fa-envelope" />
                    </span>
                    {errorMessage ? (
                      <span className="icon is-small is-right">
                        <i className="fa fa-exclamation-triangle" />
                      </span>
                    ) : null}
                    <p
                      className={classnames('mt-1 p-1 has-text-primary help', {
                        'has-background-danger is-danger': errorMessage,
                      })}
                    >
                      {errorMessage === ErrorMessages.errorMessageEmailSpam.defaultMessage ? (
                        <FormattedMessage
                          {...ErrorMessages.errorMessageEmailSpam}
                          values={{
                            SupportArticleLink: (
                              <a
                                id="UnblockEmailSupportArticle"
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.salad.com/article/255-i-cant-enter-my-email-address-to-log-in"
                              >
                                <u className="has-text-primary">
                                  {intl.formatMessage(ErrorMessages.errorMessageUnblockEmailSupportArticleCopy)}
                                </u>
                              </a>
                            ),
                          }}
                        />
                      ) : errorMessage ? (
                        errorMessage
                      ) : null}
                      &nbsp;
                    </p>
                  </div>
                </div>
                <div className="field has-text-left pt-3">
                  <div className="control">
                    <Button
                      type="submit"
                      variant="secondary"
                      isLoading={submitLoading}
                      disabled={!checked}
                      label={intl.formatMessage(EnterEmailPageMessages.enterEmailPageSubmitButtonLabel)}
                    />
                  </div>
                </div>
                <p className="has-text-left subtitle is-7 has-text-primary my-5">
                  {intl.formatMessage(EnterEmailPageMessages.enterEmailPageSecondaryDescription)}
                </p>
              </div>
            </form>
            <p className="has-text-left subtitle is-6 my-5 has-text-primary">
              {intl.formatMessage(EnterEmailPageMessages.enterEmailPageAlternativeLoginText)}
            </p>
            <GoogleSignInForm isTermsAccepted={checked} />
            {isPasskeyFeatureEnabled && isPasskeySupported && (
              <div className="column is-three-quarters p-0 pt-4">
                <div className="container" style={{ opacity: checked ? 1 : 0.5 }}>
                  <Button
                    onClick={onLoginWithPasskey}
                    variant="secondary"
                    leadingIcon={
                      <img
                        src={Key}
                        style={{ width: '16px', height: '16px', position: 'relative', top: '3px' }}
                        alt={intl.formatMessage(EnterEmailPageMessages.passkeySignInButtonImageAltText)}
                      />
                    }
                    disabled={!checked}
                    label={intl.formatMessage(EnterEmailPageMessages.passkeySignInButton)}
                  />
                  <p
                    className={classnames('mt-1 p-1 has-text-primary help', {
                      'has-background-danger is-danger': isPasskeyLoginFailed,
                      'pb-5': !isPasskeyLoginFailed,
                    })}
                  >
                    {isPasskeyLoginFailed && intl.formatMessage(EnterEmailPageMessages.passkeyAuthenticationFailure)}
                  </p>
                </div>
              </div>
            )}
            <div className="column is-three-quarters p-0">
              <p className="has-text-left mt-6 subtitle is-7 has-text-primary">
                {intl.formatMessage(EnterEmailPageMessages.enterEmailPageThirdDescription)}
              </p>
            </div>
          </div>

          <div className="column is-half py-0 is-flex is-justify-content-right is-hidden-mobile">
            <img
              className="max-height-100vh"
              src={LoginScreenRewards}
              alt={intl.formatMessage(EnterEmailPageMessages.enterEmailPageImageAltText)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
