import { defineMessages } from 'react-intl'

export const ErrorMessages = defineMessages({
  errorMessageInvalidEmail: {
    id: 'login.errorMessage.invalidEmail',
    defaultMessage: `Please enter a valid email address`,
  },
  errorMessageCodeExpired: {
    id: 'login.errorMessage.codeExpired',
    defaultMessage: 'That code has expired. Please resend the code and try again',
  },
  errorMessageIncorrectCode: {
    id: 'login.errorMessage.incorrectCode',
    defaultMessage: 'That code is not correct. Please make sure it was entered correctly.',
  },
  errorMessageReferralCodeNotValid: {
    id: 'login.errorMessage.referralCodeNotValid',
    defaultMessage: `That doesn’t look like a referral code. Please make sure it was entered correctly.`,
  },
  errorMessageReferralCodeDoesNotExist: {
    id: 'login.errorMessage.referral',
    defaultMessage: 'That code does not exist. Please make sure it was entered correctly.',
  },
  errorMessageNetworkError: {
    id: 'login.errorMessage.networkError',
    defaultMessage: 'A network error occurred. Please check your internet connection and try again.',
  },
  errorMessageUnknownError: {
    id: 'login.errorMessage.unknownError',
    defaultMessage:
      'Something went wrong, please try again later. If this issue persists, please reach out to our support team at support@salad.com',
  },
  errorMessageEmailSpam: {
    id: 'login.errorMessage.emailSpam',
    defaultMessage:
      'Login failed due to a spam blocklist preventing Salad from sending your one-time login code email. {SupportArticleLink}',
  },
  errorMessageEmailSoftBounce: {
    id: 'login.errorMessage.emailSoftBounce',
    defaultMessage:
      'We could not deliver your one-time login code to this email address as it appears to be full. Please check that your inbox has available storage, if the issue persists for more than 24 hours please contact support.',
  },
  errorMessageUnblockEmailSupportArticleCopy: {
    id: 'login.errorMessage.unblockEmailSupportArticleCopy',
    defaultMessage: 'Please follow this guide to resolve this issue.',
  },
  errorMessageAccountConnectionFailed: {
    id: 'login.errorMessage.accountConnectionFailed',
    defaultMessage: 'Google Sign-in failed, please try again.',
  },
})
