import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface ApiState {
  connected: boolean
}

export const initialState: ApiState = {
  connected: false,
}

export const saladBowlSlice = createSlice({
  name: 'saladBowl',
  initialState,
  reducers: {
    connectedToSaladBowl: (state, action: PayloadAction<{ connected: boolean; isPasskeyLogin?: boolean }>) => {
      state.connected = action.payload.connected
    },
  },
})

export const { connectedToSaladBowl } = saladBowlSlice.actions
