import { defineMessages } from 'react-intl'

export const FetchingIngredientsPageMessages = defineMessages({
  fetchingIngredientsPageTitle: {
    id: 'login.fetchingIngredientsPage.title',
    defaultMessage: 'Fetching Ingredients',
  },
  fetchingIngredientsPageImageAltText: {
    id: 'login.fetchingIngredientsPage.imageAltText',
    defaultMessage: 'Rewards',
  },
})
