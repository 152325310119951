import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import HorizontalRewardBanner from '../assets/HorizontalRewardBanner.png'
import { LoginCompletedPageMessages } from '../messages/LoginCompletedPageMessages'

interface LoginCompletedPageProps {}

export const LoginCompletedPage: FunctionComponent<LoginCompletedPageProps> = () => {
  const intl = useIntl()

  return (
    <div className="container pt-6 has-text-centered">
      <h1 className="title title-size pt-6">
        {intl.formatMessage(LoginCompletedPageMessages.LoginCompletedPageTitle)}
      </h1>
      <p className="subtitle has-text-primary is-size-5 pt-3">
        {intl.formatMessage(LoginCompletedPageMessages.LoginCompletedPageDescription)}
      </p>
      <figure className="image">
        <img
          src={HorizontalRewardBanner}
          alt={intl.formatMessage(LoginCompletedPageMessages.LoginCompletedPageImageAltText)}
        />
      </figure>
    </div>
  )
}
