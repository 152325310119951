import { defineMessages } from 'react-intl'

export const DownloadStartedPageMessages = defineMessages({
  DownloadStartedPageTitle: {
    id: 'login.downloadStartedPage.title',
    defaultMessage: `Welcome to the Kitchen, {userName}! Just a few steps left`,
  },
  DownloadStartedPageFirstPanel: {
    id: 'login.downloadStartedPage.firstPanel',
    defaultMessage: 'Step 1',
  },
  DownloadStartedPageSecondPanel: {
    id: 'login.downloadStartedPage.secondPanel',
    defaultMessage: 'Step 2',
  },
  DownloadStartedPageThirdPanel: {
    id: 'login.downloadStartedPage.thirdPanel',
    defaultMessage: 'Step 3',
  },
  DownloadStartedPageFirstPanelTitle: {
    id: 'login.downloadStartedPage.firstPanelTitle',
    defaultMessage: 'Open',
  },
  DownloadStartedPageSecondPanelTitle: {
    id: 'login.downloadStartedPage.secondPanelTitle',
    defaultMessage: 'Allow',
  },
  DownloadStartedPageThirdPanelTitle: {
    id: 'login.downloadStartedPage.thirdPanelTitle',
    defaultMessage: 'Install',
  },
  DownloadStartedPageFirstPanelDescription: {
    id: 'login.downloadStartedPage.firstPanelDescription',
    defaultMessage: 'Open the Salad.exe file from your browser’s downloads page.',
  },
  DownloadStartedPageSecondPanelDescription: {
    id: 'login.downloadStartedPage.secondPanelDescription',
    defaultMessage: 'If prompted, click “Install anyway” and “Yes” on the system dialogs.',
  },
  DownloadStartedPageThirdPanelDescription: {
    id: 'login.downloadStartedPage.thirdPanelDescription',
    defaultMessage: 'Wait for the installation to finish. Salad will open automatically when it’s done.',
  },
  DownloadStartedPageFirstPanelImageAltText: {
    id: 'login.downloadStartedPage.firstPanelImageAltText',
    defaultMessage: 'Download Step 1',
  },
  DownloadStartedPageSecondPanelImageAltText: {
    id: 'login.downloadStartedPage.secondPanelImageAltText',
    defaultMessage: 'Download Step 2',
  },
  DownloadStartedPageThirdPanelImageAltText: {
    id: 'login.downloadStartedPage.thirdPanelImageAltText',
    defaultMessage: 'Download Step 3',
  },
  DownloadStartedPageDescription: {
    id: 'login.downloadStartedPage.description',
    defaultMessage: `Your download will begin automatically. If it didn't start,`,
  },
  DownloadStartedPageDescriptionCallToAction: {
    id: 'login.downloadStartedPage.callToAction',
    defaultMessage: 'download Salad Manually',
  },
})
