import { Button } from '@saladtechnologies/garden-components'
import classnames from 'classnames'
import type { ChangeEvent, FormEventHandler, FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import LoginScreenRewards from '../assets/LoginScreenRewards.png'
import { checkIsSuccessfulGoogleProviderLogin } from '../epics/utils'
import { selectLoginCodeErrorMessage, selectLoginCodeSubmitLoading, selectLoginEmail } from '../features/apiSelectors'
import {
  resetSSOLogin,
  setLoginCode,
  setLoginCodeErrorMessage,
  setPage,
  setResendLoginCode,
} from '../features/apiSlice'
import { useAppDispatch, useAppSelector } from '../features/hook'
import { EnterCodePageMessages } from '../messages'
import { LoginPageEnum } from '../models'

export interface EnterCodePageProps {
  onResendCode: () => void
  onBack: () => void
  emailAddress?: string
  submitLoading: boolean
  errorMessage?: string
  onSubmit: (code: string) => void
}

export const ConnectedEnterCodePage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const currentEmail = useAppSelector(selectLoginEmail)
  const loginCodeSubmitLoading = useAppSelector(selectLoginCodeSubmitLoading)
  const loginCodeErrorMessage = useAppSelector(selectLoginCodeErrorMessage)

  const onSubmit = useCallback(
    (code: string) => {
      dispatch(setLoginCode(code.trim()))
    },
    [dispatch],
  )

  const onBack = useCallback(() => {
    dispatch(setPage(LoginPageEnum.ENTER_EMAIL))
    dispatch(setLoginCodeErrorMessage(undefined))

    const isSuccessfulGoogleProviderLogin = checkIsSuccessfulGoogleProviderLogin()

    if (isSuccessfulGoogleProviderLogin) {
      dispatch(resetSSOLogin())
    }
  }, [dispatch])

  const onResendCode = useCallback(() => {
    currentEmail && dispatch(setResendLoginCode(currentEmail))
  }, [dispatch, currentEmail])

  return (
    <EnterCodePage
      onResendCode={onResendCode}
      onBack={onBack}
      emailAddress={currentEmail}
      submitLoading={loginCodeSubmitLoading}
      errorMessage={loginCodeErrorMessage}
      onSubmit={onSubmit}
    />
  )
}

export const EnterCodePage: FunctionComponent<EnterCodePageProps> = ({
  onResendCode,
  onBack,
  emailAddress,
  submitLoading,
  errorMessage,
  onSubmit,
}) => {
  const [code, setCode] = useState('')
  const intl = useIntl()

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(code.trim())
    },
    [code, onSubmit],
  )

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }, [])

  return (
    <div className="is-flex">
      <div className="container">
        <div className="columns my-0">
          <div className="column is-half pt-6">
            <form onSubmit={handleSubmit}>
              <div className="container mx-2 pt-6">
                <h1 className="title pt-6 is-1">{intl.formatMessage(EnterCodePageMessages.enterCodePageTitle)}</h1>
                <div className="column is-two-thirds p-0">
                  <p className="subtitle is-6 pt-2 has-text-primary">
                    {intl.formatMessage(EnterCodePageMessages.enterCodePageDescriptionText)} {emailAddress}
                  </p>
                  <div className="pt-3 is-underlined is-clickable has-text-primary" onClick={onResendCode}>
                    {intl.formatMessage(EnterCodePageMessages.enterCodePageResend)}
                  </div>
                  <div className="field pt-5 has-text-primary">
                    <label className="label mb-0 has-text-primary" htmlFor="code-input">
                      {intl.formatMessage(EnterCodePageMessages.enterCodePageInputTitle)}
                    </label>

                    <div className={classnames('control has-icons-left', { 'has-icons-right': errorMessage })}>
                      <input
                        className={classnames('input', { 'is-danger': errorMessage, 'is-success': !errorMessage })}
                        id="code-input"
                        type="text"
                        placeholder={intl.formatMessage(EnterCodePageMessages.enterCodePageInputPlaceholder)}
                        value={code}
                        onChange={handleChange}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-key" />
                      </span>
                      {errorMessage ? (
                        <span className="icon is-small is-right">
                          <i className="fa fa-exclamation-triangle" />
                        </span>
                      ) : null}
                      <p
                        className={classnames('mt-1 p-1 has-text-primary help', {
                          'has-background-danger is-danger': errorMessage,
                        })}
                      >
                        {errorMessage ?? null} &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className="field pt-1">
                    <div className="control ">
                      <Button
                        type="submit"
                        variant="secondary"
                        isLoading={submitLoading}
                        onClick={handleSubmit as () => void}
                        label={intl.formatMessage(EnterCodePageMessages.enterCodePageSubmitButtonLabel)}
                      />
                    </div>
                  </div>
                  <div className="pt-3 is-underlined is-clickable has-text-link" onClick={onBack}>
                    {intl.formatMessage(EnterCodePageMessages.enterCodePageWrongEmail)}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-half py-0 is-flex is-justify-content-right is-hidden-mobile">
            <img
              className="max-height-100vh"
              src={LoginScreenRewards}
              alt={intl.formatMessage(EnterCodePageMessages.enterCodePageImageAltText)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
