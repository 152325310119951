export const isTestEnvironment = /testing|localhost/.test(process.env.REACT_APP_API_URL as string)

export const apiUrl = process.env.REACT_APP_API_URL as string

export const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN

export const redirectUrlBase = process.env.REACT_APP_REDIRECT_URL as string

export const unleashUrl = process.env.REACT_APP_UNLEASH_URL as string

export const unleashApiKey = process.env.REACT_APP_UNLEASH_API_KEY as string

export const isPasskeyFeatureEnabled = true
