import { defineMessages } from 'react-intl'

export const EnterCodePageMessages = defineMessages({
  enterCodePageTitle: {
    id: 'login.enterCodePage.title',
    defaultMessage: 'Enter Your One-Time Login Code',
  },
  enterCodePageDescriptionText: {
    id: 'login.enterCodePage.descriptionText',
    defaultMessage: 'A verification code was sent to your email address ',
  },
  enterCodePageResend: {
    id: 'login.enterCodePage.resend',
    defaultMessage: 'Send it again?',
  },
  enterCodePageInputTitle: {
    id: 'login.enterCodePage.inputTitle',
    defaultMessage: 'Code',
  },
  enterCodePageInputPlaceholder: {
    id: 'login.enterCodePage.inputPlaceholder',
    defaultMessage: 'Enter Your Code',
  },
  enterCodePageSubmitButtonLabel: {
    id: 'login.enterCodePage.submitButtonLabel',
    defaultMessage: 'Submit',
  },
  enterCodePageWrongEmail: {
    id: 'login.enterCodePage.wrongEmail',
    defaultMessage: 'Enter the wrong email?',
  },
  enterCodePageImageAltText: {
    id: 'login.enterCodePage.imageAltText',
    defaultMessage: 'Rewards',
  },
})
