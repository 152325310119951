import {
  catchError,
  concat,
  delay,
  EMPTY,
  filter,
  first,
  from,
  ignoreElements,
  map,
  mergeMap,
  of,
  retryWhen,
  switchMap,
  tap,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs'
import { apiUrl } from '../config'
import {
  executeRedirect,
  executeUpdateViewedReferralOnboardingAndRedirect,
  loginWithPasskey,
  resetSSOLogin,
  setAuthenticatedStatus,
  setIsPasskeyLoginFailed,
  setLoginCode,
  setLoginCodeErrorMessage,
  setLoginCodeSubmitLoading,
  setLoginEmail,
  setLoginEmailErrorMessage,
  setLoginEmailSubmitLoading,
  setPage,
  setReferralCode,
  setReferralCodeErrorMessage,
  setReferralCodeSubmitLoading,
  setResendLoginCode,
} from '../features/apiSlice'
import { connectedToSaladBowl } from '../features/saladBowlSlice'
import { ErrorMessages } from '../messages'
import {
  ExternalAuthProviderLoginAction,
  ExternalAuthProviderLoginStatus,
  LoginPageEnum,
  type Profile,
} from '../models'
import { MixpanelEvent } from '../models/mixpanelEvent'
import type { AppEpic, AppEpicAnalytics, AppState } from '../store'
import { FeatureFlags, isInstallReminderClosedStorageKey, LoginMethod } from './constants'
import { fetchAsObservable, isProblemDetail, json } from './fetchAsObservable'
import {
  checkIsSuccessfulGoogleProviderLogin,
  coerceToArrayBuffer,
  coerceToBase64,
  getRedirectUriAfterLogin,
  saladBowlUserAuthenticate,
  trackLogin,
  trackLoginVisit,
} from './utils'

export const createAuthenticationSession: AppEpic = (action$, _state$, { analytics, fetch }) =>
  action$.pipe(
    filter(setLoginEmail.match),
    tap({
      next: (action) => {
        analytics.track(MixpanelEvent.EmailEntered, { email: action.payload })
      },
    }),
    mergeMap((action) =>
      concat(
        of(
          checkIsSuccessfulGoogleProviderLogin()
            ? setPage(LoginPageEnum.FETCHING_INGREDIENTS)
            : setLoginEmailSubmitLoading(true),
        ),
        of(setLoginEmailErrorMessage(undefined)),
        fetchAsObservable(fetch, `${apiUrl}/api/v2/authentication-sessions`, {
          method: 'POST',
          body: json({
            email: action.payload,
            termsAccepted: true,
          }),
          credentials: 'include',
        }).pipe(ignoreElements()),
        of(setPage(LoginPageEnum.ENTER_CODE), setLoginEmailSubmitLoading(false)),
      ).pipe(
        retryWhen((error$) =>
          error$.pipe(
            mergeMap((error, failures) =>
              failures >= 2 || (error instanceof Response && error.status === 400)
                ? throwError(() => error)
                : timer(Math.min(Math.pow(2, failures) * 1000 + Math.floor(Math.random() * 1000), 30000)).pipe(first()),
            ),
          ),
        ),
        catchError((error) =>
          (error instanceof Response
            ? from(error.json()).pipe(
                map((errorResponse) => {
                  let errorMessage
                  if (isProblemDetail(errorResponse)) {
                    errorMessage =
                      error.status === 400 && errorResponse.type === 'emailSend:rejected:spam'
                        ? ErrorMessages.errorMessageEmailSpam.defaultMessage
                        : error.status === 400 && errorResponse.type === 'emailSend:rejected:soft-bounce'
                        ? ErrorMessages.errorMessageEmailSoftBounce.defaultMessage
                        : error.status === 400
                        ? ErrorMessages.errorMessageInvalidEmail.defaultMessage
                        : ErrorMessages.errorMessageUnknownError.defaultMessage
                  } else {
                    errorMessage = ErrorMessages.errorMessageUnknownError.defaultMessage
                  }
                  return errorMessage
                }),
                catchError(() => of(ErrorMessages.errorMessageNetworkError.defaultMessage)),
              )
            : of(ErrorMessages.errorMessageNetworkError.defaultMessage)
          ).pipe(
            tap({
              next: (errorMessage) => {
                analytics.track(MixpanelEvent.EmailEnteredError, { errorMessage })
              },
            }),
            mergeMap((errorMessage) =>
              of(
                setLoginEmailErrorMessage(errorMessage),
                setLoginEmailSubmitLoading(false),
                setPage(LoginPageEnum.ENTER_EMAIL),
              ),
            ),
          ),
        ),
      ),
    ),
  )

export const resetSSOLoginEpic: AppEpic = (action$) =>
  action$.pipe(
    filter(resetSSOLogin.match),
    tap(() => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      urlSearchParams.delete('external-login-action')
      urlSearchParams.delete('external-user-email')
      urlSearchParams.delete('external-login-status')
      window.location.search = urlSearchParams.toString()
    }),
    ignoreElements(),
  )

const fetchProfile = (analytics: AppEpicAnalytics) =>
  fetchAsObservable(fetch, `${apiUrl}/api/v1/profile`, {
    method: 'GET',
    credentials: 'include',
  }).pipe(
    switchMap((response) => from(response.json())),
    retryWhen((error$) =>
      error$.pipe(
        switchMap((error, failures) => {
          return failures >= 2 || (error instanceof Response && error.status === 400)
            ? throwError(() => error)
            : timer(Math.min(Math.pow(2, failures) * 1000 + Math.floor(Math.random() * 1000), 30000)).pipe(first())
        }),
      ),
    ),
    mergeMap((profile: Profile) => {
      trackLogin(profile, analytics)
      analytics.track(MixpanelEvent.CodeEnteredSuccess)

      if (profile.pendingTermsVersion) {
        return fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/terms`, {
          method: 'POST',
          body: profile.pendingTermsVersion,
          credentials: 'include',
        }).pipe(catchError(() => of(profile.viewedReferralOnboarding)))
      }
      return of(profile.viewedReferralOnboarding)
    }),
  )

const processAuthRedirect = (state: AppState) =>
  of(state).pipe(
    mergeMap(({ startup, saladBowl }) => {
      const { redirectUri } = startup
      const redirectTo = getRedirectUriAfterLogin(redirectUri)
      return concat(
        of(setAuthenticatedStatus(true)),
        of(setLoginCodeSubmitLoading(false)),
        of(
          startup.redirectApp
            ? setPage(saladBowl.connected ? LoginPageEnum.LOGIN_COMPLETED : LoginPageEnum.CONNECTING_TO_SALAD)
            : executeRedirect(redirectTo),
        ),
      )
    }),
  )

const fetchReferral = (state: AppState, analytics: AppEpicAnalytics) =>
  fetchProfile(analytics).pipe(
    switchMap((viewedReferralOnboarding) =>
      fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/referral`, {
        method: 'GET',
        credentials: 'include',
      }).pipe(
        mergeMap((response) => {
          const redirectUri = state.startup.redirectUri
          if (redirectUri !== undefined && response.status !== 204) {
            analytics.track(MixpanelEvent.RedirectURI, { redirectUri: state.startup.redirectUri })
          }

          if (response.status === 204 && !viewedReferralOnboarding) {
            return concat(
              of(setAuthenticatedStatus(true)),
              of(setLoginCodeSubmitLoading(false)),
              of(setPage(LoginPageEnum.ONBOARDING_ENTER_REFERRAL_CODE)),
            )
          }
          return processAuthRedirect(state)
        }),
        retryWhen((error$) =>
          error$.pipe(
            mergeMap((error, failures) =>
              failures >= 2 || (error instanceof Response && error.status === 400)
                ? throwError(() => error)
                : timer(Math.min(Math.pow(2, failures) * 1000 + Math.floor(Math.random() * 1000), 30000)).pipe(first()),
            ),
          ),
        ),
      ),
    ),
  )

export const verifyAuthenticationSession: AppEpic = (action$, state$, { analytics, fetch, unleash }) =>
  action$.pipe(
    filter(setLoginCode.match),
    mergeMap((action) =>
      concat(
        of(setLoginCodeSubmitLoading(true), setLoginCodeErrorMessage(undefined)),
        fetchAsObservable(fetch, `${apiUrl}/api/v2/authentication-sessions/verification`, {
          method: 'POST',
          body: json({
            passcode: action.payload,
          }),
          credentials: 'include',
        }).pipe(
          mergeMap(() =>
            state$.value.startup.redirectApp
              ? saladBowlUserAuthenticate(state$.value)
              : of(connectedToSaladBowl({ connected: false })),
          ),
          switchMap(() =>
            unleash?.isEnabled(FeatureFlags.ReferralCode)
              ? fetchReferral(state$.value, analytics)
              : fetchProfile(analytics).pipe(switchMap(() => processAuthRedirect(state$.value))),
          ),
          retryWhen((error$) =>
            error$.pipe(
              mergeMap((error, failures) =>
                failures >= 2 || (error instanceof Response && error.status === 400)
                  ? throwError(() => error)
                  : timer(Math.min(Math.pow(2, failures) * 1000 + Math.floor(Math.random() * 1000), 30000)).pipe(
                      first(),
                    ),
              ),
            ),
          ),
          catchError((error) =>
            (error instanceof Response
              ? from(error.json()).pipe(
                  map((errorResponse) => {
                    let errorMessage
                    if (isProblemDetail(errorResponse)) {
                      errorMessage =
                        errorResponse.type === 'invalid_session'
                          ? ErrorMessages.errorMessageCodeExpired.defaultMessage
                          : ErrorMessages.errorMessageIncorrectCode.defaultMessage
                    } else {
                      errorMessage = ErrorMessages.errorMessageIncorrectCode.defaultMessage
                    }

                    return errorMessage
                  }),
                  catchError(() => of(ErrorMessages.errorMessageNetworkError.defaultMessage)),
                )
              : of(ErrorMessages.errorMessageNetworkError.defaultMessage)
            ).pipe(
              tap({
                next: (errorMessage) => {
                  analytics.track(MixpanelEvent.CodeEnteredError, { errorMessage })
                },
              }),
              mergeMap((errorMessage) => of(setLoginCodeErrorMessage(errorMessage), setLoginCodeSubmitLoading(false))),
            ),
          ),
        ),
      ),
    ),
  )

export const resendLoginCode: AppEpic = (action$, _state$, { fetch, analytics }) =>
  action$.pipe(
    filter(setResendLoginCode.match),
    mergeMap((action) =>
      concat(
        of(setLoginCodeErrorMessage(undefined)),
        fetchAsObservable(fetch, `${apiUrl}/api/v2/authentication-sessions`, {
          method: 'POST',
          body: json({
            email: action.payload,
            termsAccepted: true,
          }),
          credentials: 'include',
        }).pipe(ignoreElements()),
      ).pipe(
        catchError((error) => {
          const errorMessage =
            error.status === 400
              ? ErrorMessages.errorMessageInvalidEmail.defaultMessage
              : ErrorMessages.errorMessageUnknownError.defaultMessage
          analytics.track(MixpanelEvent.EmailEnteredError, { errorMessage })

          return of(setLoginEmailErrorMessage(errorMessage), setLoginEmailSubmitLoading(false))
        }),
      ),
    ),
  )

export const sendReferralCode: AppEpic = (action$, _state$, { fetch, analytics }) =>
  action$.pipe(
    filter(setReferralCode.match),
    tap({
      next: (action) => {
        if (action.payload.track) {
          analytics.track(MixpanelEvent.ReferralEntered, { referralCode: action.payload.code })
        }
      },
    }),
    mergeMap((action) =>
      concat(
        of(setReferralCodeSubmitLoading(true), setReferralCodeErrorMessage(undefined)),
        fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/referral`, {
          method: 'POST',
          body: json({
            code: action.payload.code,
          }),
          credentials: 'include',
        }).pipe(
          tap(() => {
            if (action.payload.track) {
              analytics.track(MixpanelEvent.ReferralEntered, { referralCode: action.payload.code })
            }
          }),
          ignoreElements(),
        ),
        of(setReferralCodeSubmitLoading(false), executeUpdateViewedReferralOnboardingAndRedirect()),
      ).pipe(
        retryWhen((error$) =>
          error$.pipe(
            mergeMap((error, failures) =>
              failures >= 2 || (error instanceof Response && (error.status === 400 || 409))
                ? throwError(() => error)
                : timer(Math.min(Math.pow(2, failures) * 1000 + Math.floor(Math.random() * 1000), 30000)).pipe(first()),
            ),
          ),
        ),
        catchError((error) => {
          const errorMessage = !(error instanceof Response)
            ? ErrorMessages.errorMessageNetworkError.defaultMessage
            : error.status === 400
            ? ErrorMessages.errorMessageReferralCodeNotValid.defaultMessage
            : error.status === 409
            ? ErrorMessages.errorMessageReferralCodeDoesNotExist.defaultMessage
            : ErrorMessages.errorMessageUnknownError.defaultMessage

          if (action.payload.track) {
            analytics.track(MixpanelEvent.ReferralEnteredError, { errorMessage })
          }

          return of(setReferralCodeErrorMessage(errorMessage), setReferralCodeSubmitLoading(false))
        }),
      ),
    ),
  )

export const onStart: AppEpic = (_action$, state$, { fetch, analytics }) =>
  state$.pipe(
    first(),
    mergeMap(() => {
      return concat(
        of(setPage(LoginPageEnum.FETCHING_INGREDIENTS)),
        fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/`, {
          method: 'GET',
          credentials: 'include',
        }).pipe(
          mergeMap((response) => from(response.json())),
          tap((response) => {
            const redirectUri = state$.value.startup.redirectUri
            if (redirectUri !== undefined && response.viewedReferralOnboarding) {
              analytics.track(MixpanelEvent.RedirectURI, { redirectUri })
            }
          }),
          mergeMap((response) =>
            concat(
              state$.value.startup.redirectApp
                ? saladBowlUserAuthenticate(state$.value)
                : of(null).pipe(() => {
                    trackLogin(response, analytics)

                    return EMPTY
                  }),
              response.viewedReferralOnboarding
                ? of(
                    setReferralCode({ code: response.code }),
                    setAuthenticatedStatus(true),
                    state$.value.startup.redirectApp
                      ? setPage(LoginPageEnum.LOGIN_COMPLETED)
                      : executeRedirect(getRedirectUriAfterLogin(state$.value.startup.redirectUri)),
                  )
                : fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/referral`, {
                    method: 'GET',
                    credentials: 'include',
                  }).pipe(
                    mergeMap((response) => from(response.json())),
                    mergeMap(() => {
                      const { redirectUri, redirectApp } = state$.value.startup

                      if (redirectUri !== undefined) {
                        analytics.track(MixpanelEvent.RedirectURI, { redirectUri })
                      }

                      if (redirectApp && state$.value.saladBowl.connected) {
                        return of(setAuthenticatedStatus(true), setPage(LoginPageEnum.LOGIN_COMPLETED)).pipe(
                          delay(3000),
                        )
                      } else if (redirectApp && !state$.value.saladBowl.connected) {
                        // TODO: What page or flow do we show when we can't connect to Salad Bowl
                        return of(setAuthenticatedStatus(true), setPage(LoginPageEnum.CONNECTING_TO_SALAD))
                      } else {
                        const redirectTo = getRedirectUriAfterLogin(redirectUri)

                        return of(setAuthenticatedStatus(true), executeRedirect(redirectTo))
                      }
                    }),
                    catchError(() =>
                      of(setAuthenticatedStatus(true), setPage(LoginPageEnum.ONBOARDING_ENTER_REFERRAL_CODE)),
                    ),
                  ),
            ),
          ),
          // *NOTE: This catch block represents the logic for unauthenticated user
          catchError(() => {
            const urlSearchParams = new URLSearchParams(window.location.search)
            const externalLoginAction = urlSearchParams.get('external-login-action')
            const externalUserEmail = urlSearchParams.get('external-user-email')
            const externalLoginStatus = urlSearchParams.get('external-login-status')

            if (
              externalLoginStatus === ExternalAuthProviderLoginStatus.Success &&
              externalLoginAction === ExternalAuthProviderLoginAction.OneTimeCodeFlow &&
              !!externalUserEmail
            ) {
              return of(setLoginEmail(externalUserEmail))
            }

            trackLoginVisit(analytics)

            // this data is set in web-app (on install reminder banner closed)
            localStorage.removeItem(isInstallReminderClosedStorageKey)
            return of(setPage(LoginPageEnum.ENTER_EMAIL)).pipe(delay(1000))
          }),
        ),
      )
    }),
  )

export const setViewedReferralOnboardingAndRedirect: AppEpic = (action$, state$, { fetch, analytics }) =>
  action$.pipe(
    filter(executeUpdateViewedReferralOnboardingAndRedirect.match),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const { redirectUri, redirectApp } = state.startup
      const redirectTo = getRedirectUriAfterLogin(redirectUri)

      return concat(
        fetchAsObservable(fetch, `${apiUrl}/api/v1/profile/`, {
          method: 'PATCH',
          body: json({
            viewedReferralOnboarding: true,
          }),
          credentials: 'include',
        }).pipe(
          tap({
            next: () => {
              if (redirectTo !== undefined) {
                analytics.track(MixpanelEvent.RedirectURI, { redirectTo })
              }
            },
          }),
          ignoreElements(),
        ),
        redirectApp ? of(setPage(LoginPageEnum.LOGIN_COMPLETED)) : of(executeRedirect(redirectTo)),
      )
    }),
  )

export const trackSaladBowlLogins: AppEpic = (action$, _state$, { analytics }) =>
  action$.pipe(
    filter(connectedToSaladBowl.match),
    tap((action) => {
      if (action.payload.connected === true) {
        const isSuccessfulGoogleProviderLogin = checkIsSuccessfulGoogleProviderLogin()

        let saladBowlLoginMethod
        if (action.payload.isPasskeyLogin) {
          saladBowlLoginMethod = LoginMethod.Passkey
        } else if (isSuccessfulGoogleProviderLogin) {
          saladBowlLoginMethod = LoginMethod.Google
        } else {
          saladBowlLoginMethod = LoginMethod.Passkey
        }

        analytics.track(MixpanelEvent.SaladBowlLogin, { Method: saladBowlLoginMethod })
      }
    }),
    ignoreElements(),
  )

interface PasskeyAssertionsOptionsResponse {
  challenge: string
  errorMessage: string
  rpId: string
}

const fetchPasskeyCredentials = (
  response: PasskeyAssertionsOptionsResponse,
  state: AppState,
  analytics: AppEpicAnalytics,
) => {
  // eslint-disable-next-line compat/compat
  const abortController = new AbortController()

  const publicKeyCredentialRequestOptions = {
    challenge: coerceToArrayBuffer(response.challenge),
    rpId: response.rpId,
  }

  const credentialsRequest = from(
    // eslint-disable-next-line compat/compat
    navigator.credentials.get({
      publicKey: publicKeyCredentialRequestOptions,
      signal: abortController.signal,
      mediation: 'optional',
    }) as Promise<PublicKeyCredential>,
  )

  return credentialsRequest.pipe(
    mergeMap((credentials: PublicKeyCredential) => {
      const response = credentials.response as AuthenticatorAssertionResponse

      const transformedCredentials = {
        assertion: {
          id: credentials.id,
          type: credentials.type,
          authenticatorAttachment: credentials.authenticatorAttachment,
          rawId: coerceToBase64(credentials.rawId),
          response: {
            authenticatorData: coerceToBase64(response.authenticatorData),
            clientDataJSON: coerceToBase64(response.clientDataJSON),
            signature: coerceToBase64(response.signature),
            userHandle: coerceToBase64(response.userHandle as ArrayBuffer),
          },
        },
        termsAccepted: true,
      }

      return fetchAsObservable(fetch, `${apiUrl}/api/v2/passkeys/assertions`, {
        method: 'POST',
        body: json(transformedCredentials),
        credentials: 'include',
      }).pipe(
        mergeMap(() =>
          state.startup.redirectApp
            ? saladBowlUserAuthenticate(state, true)
            : of(null).pipe(() => {
                analytics.track(MixpanelEvent.Auth, {
                  method: LoginMethod.Passkey,
                })
                return of(
                  setAuthenticatedStatus(true),
                  setIsPasskeyLoginFailed(false),
                  state.startup.redirectApp
                    ? setPage(LoginPageEnum.LOGIN_COMPLETED)
                    : executeRedirect(getRedirectUriAfterLogin(state.startup.redirectUri)),
                )
              }),
        ),
        catchError((error) => throwError(() => error)),
      )
    }),
    catchError((error) =>
      concat(
        of(connectedToSaladBowl({ connected: false })),
        throwError(() => error),
      ),
    ),
  )
}

export const loginWithPasskeyEpic: AppEpic = (action$, state$, { analytics }) =>
  action$.pipe(
    filter(loginWithPasskey.match),
    switchMap(() =>
      fetchAsObservable(fetch, `${apiUrl}/api/v2/passkeys/assertions/options`, {
        method: 'POST',
        credentials: 'include',
      }).pipe(
        switchMap((response) => from(response.json())),
        switchMap((response: PasskeyAssertionsOptionsResponse) =>
          fetchPasskeyCredentials(response, state$.value, analytics),
        ),
        catchError(() => of(setIsPasskeyLoginFailed(true))),
      ),
    ),
  )
