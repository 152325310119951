import { ThemeProvider } from '@emotion/react'
import type { AnyAction } from '@reduxjs/toolkit'
import { DefaultTheme } from '@saladtechnologies/garden-components'
import mixpanel from 'mixpanel-browser'
import type { ReactNode } from 'react'
import { PureComponent } from 'react'
import type { IntlCache, IntlShape } from 'react-intl'
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl'
import { Provider } from 'react-redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { UnleashClient } from 'unleash-proxy-client'
import { LoginContainer } from './LoginContainer'
import { mixpanelToken, unleashApiKey, unleashUrl } from './config'
import {
  createAuthenticationSession,
  loginWithPasskeyEpic,
  onStart,
  resendLoginCode,
  resetSSOLoginEpic,
  sendReferralCode,
  setViewedReferralOnboardingAndRedirect,
  trackSaladBowlLogins,
  verifyAuthenticationSession,
} from './epics/login'
import type { Profile } from './models'
import type { AppEpicDependencies, AppEpicMiddleware, AppState, AppStore } from './store'
import { createStore } from './store'

export interface AppComponentProps {
  preloadedState?: Partial<AppState>
}

interface AppComponentState {
  intl: IntlShape
  lang: string
}

export class AppComponent extends PureComponent<AppComponentProps, AppComponentState> {
  private readonly epicMiddleware: AppEpicMiddleware

  private readonly intlCache: IntlCache

  private readonly store: AppStore

  private disconnect: (() => void) | undefined

  private htmlObserver: MutationObserver | undefined

  constructor(props: AppComponentProps) {
    super(props)
    const { preloadedState } = this.props

    const unleashClient = new UnleashClient({
      url: unleashUrl,
      clientKey: unleashApiKey,
      appName: 'login',
      refreshInterval: 60,
      metricsInterval: 60,
    })

    this.epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState, AppEpicDependencies>({
      dependencies: {
        analytics: {
          identify: mixpanelToken
            ? (profile: Profile) => {
                mixpanel.identify(profile.id)
              }
            : () => {},
          track: mixpanelToken
            ? (eventName, eventProperties) => {
                mixpanel.track(eventName, eventProperties)
              }
            : () => {},
        },
        unleash: unleashClient,
        fetch: window.fetch,
      },
    })
    this.intlCache = createIntlCache()
    this.store = createStore(this.epicMiddleware, preloadedState)

    this.state = {
      intl: createIntl(
        {
          locale: window.document.documentElement.lang,
          messages: {},
          onError: (err) => {
            if (err.code === 'MISSING_TRANSLATION') {
              return
            }
            throw err
          },
        },
        this.intlCache,
      ),
      lang: window.document.documentElement.lang,
    }

    this.epicMiddleware.run(
      combineEpics(
        createAuthenticationSession,
        verifyAuthenticationSession,
        resetSSOLoginEpic,
        resendLoginCode,
        sendReferralCode,
        onStart,
        setViewedReferralOnboardingAndRedirect,
        trackSaladBowlLogins,
        loginWithPasskeyEpic,
      ),
    )
  }

  override componentDidMount() {
    this.htmlObserver = new MutationObserver((mutations) => {
      const { lang } = this.state
      for (let i = 0; i < mutations.length; i += 1) {
        const mutation = mutations[i]
        if (
          mutation &&
          mutation.type === 'attributes' &&
          mutation.attributeName === 'lang' &&
          lang !== window.document.documentElement.lang
        ) {
          this.setState({
            lang: window.document.documentElement.lang,
          })
        }
      }
    })
    this.htmlObserver.observe(window.document.documentElement, {
      attributeFilter: ['lang'],
      attributeOldValue: false,
      attributes: true,
      characterData: false,
      childList: false,
      subtree: false,
    })
  }

  override componentWillUnmount() {
    if (this.disconnect !== undefined) {
      this.disconnect()
      this.disconnect = undefined
    }

    if (this.htmlObserver !== undefined) {
      this.htmlObserver.disconnect()
      this.htmlObserver = undefined
    }
  }

  override render(): ReactNode {
    const { intl } = this.state
    return (
      <ThemeProvider theme={DefaultTheme}>
        <RawIntlProvider value={intl}>
          <Provider store={this.store}>
            <LoginContainer />
          </Provider>
        </RawIntlProvider>
      </ThemeProvider>
    )
  }
}
