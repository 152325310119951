export enum MixpanelEvent {
  Auth = 'Auth',
  LoginVisit = 'Login Visit',
  EmailEntered='Email Entered',
  EmailEnteredError='Email Entered Error',
  RedirectURI='Redirect URI',
  CodeEnteredSuccess='Code Entered Success',
  CodeEnteredError='Code Entered Error',
  ReferralEntered='Referral Entered',
  ReferralEnteredError='Referral Entered Error',
  SaladBowlLogin='SaladBowl Login',
}
