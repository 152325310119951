export enum LoginMethod {
  OTC = 'OTC',
  Google = 'Google',
  Passkey = 'Passkey',
}

export enum FeatureFlags {
  ReferralCode = 'app_referralcode',
}

export const isInstallReminderClosedStorageKey = 'SALAD_IS_INSTALL_REMINDER_CLOSED'
