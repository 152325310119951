import type { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import '../animations.css'
import HorizontalRewardBanner from '../assets/HorizontalRewardBanner.png'
import { StillConnectingToSaladPageMessages } from '../messages/StillConnectingToSaladPageMessages'

interface StillConnectingToSaladPageProps {}

export const StillConnectingToSaladPage: FunctionComponent<StillConnectingToSaladPageProps> = () => {
  const intl = useIntl()

  return (
    <div className="container pt-6 has-text-centered">
      <h1 className="title title-size pt-6 ellipsis-loading">
        {intl.formatMessage(StillConnectingToSaladPageMessages.stillConnectingToSaladPageTitle)}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </h1>
      <p className="subtitle has-text-primary is-size-5 pt-3 container column is-half">
        <FormattedMessage
          {...StillConnectingToSaladPageMessages.stillConnectingToSaladPageDescription}
          values={{
            guide: (
              <a
                id="StillConnectingToSaladGuideLink"
                href="https://support.salad.com/article/222-i-am-having-trouble-connecting-to-the-salad-app"
              >
                <u className="has-text-primary">
                  {intl.formatMessage(StillConnectingToSaladPageMessages.stillConnectingToSaladPageGuideCopy)}
                </u>
              </a>
            ),
          }}
        />
      </p>
      <figure className="image">
        <img
          src={HorizontalRewardBanner}
          alt={intl.formatMessage(StillConnectingToSaladPageMessages.stillConnectingToSaladPageImageAltText)}
        />
      </figure>
    </div>
  )
}
