import { createSlice } from '@reduxjs/toolkit'

export interface StartupState {
  redirectUri?: string
  redirectApp?: string
  referrer?: string | null
}

export const initialState: StartupState = {}

export const startupSlice = createSlice({
  name: 'startup',
  initialState,
  reducers: {},
})
