export enum LoginPageEnum {
  CONNECTING_TO_SALAD,
  DOWNLOAD_STARTED,
  ENTER_EMAIL,
  ENTER_CODE,
  FETCHING_INGREDIENTS,
  LOGIN_COMPLETED,
  ONBOARDING_ENTER_REFERRAL_CODE,
  STILL_CONNECTING_TO_SALAD,
}

export enum ExternalAuthProviderLoginAction {
  None = 'None',
  OneTimeCodeFlow = 'OneTimeCodeFlow',
  WithMessageConfirmation = 'WithMessageConfirmation',
  WithoutMessageConfirmation = 'WithoutMessageConfirmation',
}

export enum ExternalAuthProviderLoginStatus {
  Success = 'Success',
  Failed = 'Failed',
}
