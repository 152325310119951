import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import '../animations.css'
import HorizontalRewardBanner from '../assets/HorizontalRewardBanner.png'
import { ConnectingToSaladPageMessages } from '../messages/ConnectingToSaladPageMessages'

interface ConnectingToSaladPageProps {}

export const ConnectingToSaladPage: FunctionComponent<ConnectingToSaladPageProps> = () => {
  const intl = useIntl()

  return (
    <div className="container pt-6 has-text-centered">
      <h1 className="title title-size pt-6 ellipsis-loading">
        {intl.formatMessage(ConnectingToSaladPageMessages.connectingToSaladPageTitle)}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </h1>
      <p className="subtitle has-text-primary is-size-5 pt-3">
        {intl.formatMessage(ConnectingToSaladPageMessages.connectingToSaladPageDescription)}
      </p>
      <figure className="image">
        <img
          src={HorizontalRewardBanner}
          alt={intl.formatMessage(ConnectingToSaladPageMessages.connectingToSaladPageImageAltText)}
        />
      </figure>
    </div>
  )
}
