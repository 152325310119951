// Import polyfills. Order is important!
import 'react-app-polyfill/stable'
import 'whatwg-fetch'
import 'abortcontroller-polyfill'
import 'url-polyfill'

import mixpanel from 'mixpanel-browser'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AppComponent } from './AppComponent'
import { apiUrl, mixpanelToken, redirectUrlBase } from './config'
import { downloadReferrer, selectTargetRewardRoute, storeRoute } from './features/constants'
import './index.scss'
import { LoginPageEnum } from './models'
import * as serviceWorker from './serviceWorker'

export const getRedirectRoute = (params: URLSearchParams, referrer: string | null) => {
  if (referrer === downloadReferrer) {
    return selectTargetRewardRoute
  }

  const redirectRoute = params.get('redirect_uri')

  // *NOTE: Default redirect is to storefront, /store
  return redirectRoute ?? storeRoute
}

mixpanelToken &&
  mixpanel.init(mixpanelToken, {
    api_host: `${apiUrl}/api/v2/mixpanel`,
    ignore_dnt: true,
    secure_cookie: true,
  })

const search = window.location.search
const params = new URLSearchParams(search)
const redirectApp = params.get('redirect_app')
const referrer = params.get('referrer')
let redirectUri: string | undefined

try {
  const redirectUrl = new URL(getRedirectRoute(params, referrer), redirectUrlBase)
  if (redirectUrl.href.startsWith(redirectUrlBase)) {
    redirectUri = redirectUrl.href
  }
} catch {}

const root = createRoot(document.getElementById('root')!)
root.render(
  <StrictMode>
    <AppComponent
      preloadedState={
        redirectApp
          ? {
              startup: { redirectApp, referrer },
              api: {
                pageStep: LoginPageEnum.FETCHING_INGREDIENTS,
                loginEmail: undefined,
                loginCode: undefined,
                loginEmailSubmitLoading: false,
                loginCodeErrorMessage: undefined,
                loginCodeSubmitLoading: false,
                isAuthenticated: false,
                isPasskeyLoginFailed: false,
                referralCode: undefined,
                referralCodeSubmitLoading: false,
                referralCodeErrorMessage: undefined,
              },
            }
          : { startup: { redirectUri, referrer } }
      }
    />
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
