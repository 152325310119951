import { defineMessages } from 'react-intl'

export const ConnectingToSaladPageMessages = defineMessages({
  connectingToSaladPageTitle: {
    id: 'login.connectingToSaladPage.title',
    defaultMessage: 'Connecting to Salad',
  },
  connectingToSaladPageDescription: {
    id: 'login.connectingToSaladPage.description',
    defaultMessage: 'Please be patient while we connect to the Salad App running on this PC.',
  },
  connectingToSaladPageImageAltText: {
    id: 'login.connectingToSaladPage.imageAltText',
    defaultMessage: 'Rewards',
  },
})
