import type { AppState } from '../store'

export const selectPage = (state: AppState) => state.api.pageStep
export const selectLoginEmail = (state: AppState) => state.api.loginEmail
export const selectLoginEmailSubmitLoading = (state: AppState) => state.api.loginEmailSubmitLoading
export const selectLoginEmailErrorMessage = (state: AppState) => state.api.loginEmailErrorMessage
export const selectLoginCode = (state: AppState) => state.api.loginCode
export const selectLoginCodeSubmitLoading = (state: AppState) => state.api.loginCodeSubmitLoading
export const selectLoginCodeErrorMessage = (state: AppState) => state.api.loginCodeErrorMessage
export const selectReferralCode = (state: AppState) => state.api.referralCode
export const selectReferralCodeSubmitLoading = (state: AppState) => state.api.referralCodeSubmitLoading
export const selectReferralCodeErrorMessage = (state: AppState) => state.api.referralCodeErrorMessage
export const selectIsAuthenticated = (state: AppState) => state.api.isAuthenticated
export const selectIsPasskeyLoginFailed = (state: AppState) => state.api.isPasskeyLoginFailed
