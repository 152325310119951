import type { AnyAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import type { Epic, EpicMiddleware } from 'redux-observable'
import type { UnleashClient } from 'unleash-proxy-client'
import { slice } from './features/apiSlice'
import { saladBowlSlice } from './features/saladBowlSlice'
import { startupSlice } from './features/startupSlice'
import type { Profile } from './models'

const reducer = combineReducers({
  api: slice.reducer,
  saladBowl: saladBowlSlice.reducer,
  startup: startupSlice.reducer,
})

export const createStore = (epicMiddleware: AppEpicMiddleware, preloadedState?: Partial<AppState>) =>
  configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
    reducer,
    preloadedState,
  })

export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppDispatch = ReturnType<typeof createStore>['dispatch']

export type AppEpic = Epic<AnyAction, AnyAction, AppState, AppEpicDependencies>

export interface AppEpicAnalytics {
  identify: (profile: Profile) => void
  track: (eventName: string, properties?: Record<string, any>) => void
}

export interface AppEpicDependencies {
  analytics: AppEpicAnalytics
  unleash?: UnleashClient
  fetch: (typeof window)['fetch']
}

export type AppEpicMiddleware = EpicMiddleware<AnyAction, AnyAction, AppState, AppEpicDependencies>

export type AppState = ReturnType<typeof reducer>

export type AppStore = ReturnType<typeof createStore>
