import {
  GrpcWebImpl,
  SaladBowlServiceClientImpl,
} from '@saladtechnologies/grpc-web-salad-bowl/salad/grpc/salad_bowl/v1alpha/service_v1alpha'
import { catchError, concat, defer, from, map, mergeMap, of, retry, startWith, tap, throwError, timer } from 'rxjs'
import { apiUrl, redirectUrlBase } from '../config'
import { setPage } from '../features/apiSlice'
import { accountSummaryRoute } from '../features/constants'
import { connectedToSaladBowl } from '../features/saladBowlSlice'
import { ExternalAuthProviderLoginStatus, LoginPageEnum, type Profile } from '../models'
import { MixpanelEvent } from '../models/mixpanelEvent'
import type { AppEpicAnalytics, AppState } from '../store'
import { LoginMethod } from './constants'
import { fetchAsObservable } from './fetchAsObservable'

export const getRedirectUriAfterLogin = (uri: string | undefined) => {
  const uriSearchParams = new URLSearchParams(window.location.search)
  const externalLoginProviderStatus = uriSearchParams.get('external-login-status')
  const externalLoginProviderAction = uriSearchParams.get('external-login-action')
  const preservedSearchParams = localStorage.getItem('preserved_search_params')
  const prefixedSearchParams = preservedSearchParams ? `${preservedSearchParams}&` : '?'

  if (preservedSearchParams !== null) {
    localStorage.removeItem('preserved_search_params')
  }

  if (externalLoginProviderStatus && externalLoginProviderAction) {
    return `${redirectUrlBase}${accountSummaryRoute}${prefixedSearchParams}external-login-status=${externalLoginProviderStatus}&external-login-action=${externalLoginProviderAction}`
  }

  return uri
}

export const checkIsSuccessfulGoogleProviderLogin = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const externalLoginProviderStatus = urlSearchParams.get('external-login-status')
  const externalLoginProviderAction = urlSearchParams.get('external-login-action')

  return Boolean(externalLoginProviderStatus === ExternalAuthProviderLoginStatus.Success && externalLoginProviderAction)
}

export const trackLogin = (profile: Profile, analytics: AppEpicAnalytics) => {
  analytics.identify(profile)

  const isSuccessfulGoogleProviderLogin = checkIsSuccessfulGoogleProviderLogin()

  analytics.track(MixpanelEvent.Auth, {
    method: isSuccessfulGoogleProviderLogin ? LoginMethod.Google : LoginMethod.OTC,
  })
}

export const trackLoginVisit = (analytics: AppEpicAnalytics) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const referrer = urlSearchParams.get('referrer')

  if (referrer) {
    analytics.track(MixpanelEvent.LoginVisit, { referrer })
  } else {
    analytics.track(MixpanelEvent.LoginVisit)
  }
}

export const coerceToBase64 = (input: ArrayBuffer | Uint8Array | Array<any>) => {
  let base65
  // Array or ArrayBuffer to Uint8Array
  if (Array.isArray(input)) {
    base65 = Uint8Array.from(input)
  }

  if (input instanceof ArrayBuffer) {
    base65 = new Uint8Array(input)
  }

  if (input instanceof Uint8Array) {
    base65 = input
  }

  // Uint8Array to base64
  if (base65 instanceof Uint8Array) {
    let str = ''
    const len = base65.byteLength

    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(base65[i] as number)
    }
    base65 = window.btoa(str)
  }

  if (typeof base65 !== 'string') {
    throw new Error('could not coerce to string')
  }

  // base64 to base64url
  // NOTE: "=" at the end of challenge is optional, strip it off here
  base65 = base65.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '')

  return base65
}

export const coerceToArrayBuffer = (input: string) => {
  let output

  if (typeof input === 'string') {
    // base64url to base64
    output = input.replace(/-/g, '+').replace(/_/g, '/')

    // base64 to Uint8Array
    output = window.atob(output)
    const bytes = new Uint8Array(output.length)
    for (let i = 0; i < output.length; i++) {
      bytes[i] = output.charCodeAt(i)
    }
    output = bytes
  }

  // Array to Uint8Array
  if (Array.isArray(input)) {
    output = new Uint8Array(input)
  }

  // Uint8Array to ArrayBuffer
  if (output instanceof Uint8Array) {
    output = output.buffer
  }

  // error if none of the above worked
  if (!(output instanceof ArrayBuffer)) {
    throw new TypeError('could not coerce to ArrayBuffer')
  }

  return output
}

export const getIsPasskeySupported = async (): Promise<boolean> => {
  // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
  // `isUserVerifyingPlatformAuthenticatorAvailable` means the feature detection is usable.
  // `​​isConditionalMediationAvailable` means the feature detection is usable.
  if (
    window.PublicKeyCredential &&
    typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable === 'function' &&
    typeof PublicKeyCredential.isConditionalMediationAvailable === 'function'
  ) {
    try {
      const [isAuthenticatorAvailable, isMediationAvailable] = await Promise.all([
        PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
        PublicKeyCredential.isConditionalMediationAvailable(),
      ])

      return isAuthenticatorAvailable && isMediationAvailable
    } catch (error) {
      console.error('Error checking passkey support:', error)
      return false
    }
  }

  return false
}

export const saladBowlUserAuthenticate = (state: AppState, isPasskeyLogin: boolean = false) =>
  defer(() =>
    fetchAsObservable(fetch, `${apiUrl}/api/v2/saladbowl/auth/login`, {
      method: 'POST',
      credentials: 'include',
    }).pipe(
      mergeMap((response) => from(response.text())),
      mergeMap((jwtResponse) => {
        let initialPortNumber
        if (state.startup.redirectApp) {
          initialPortNumber = Number.parseInt(state.startup.redirectApp)
        } else {
          initialPortNumber = 5000
        }

        if (initialPortNumber < 5000) {
          initialPortNumber = 5000
        } else if (initialPortNumber > 5010) {
          initialPortNumber = 5010
        }

        let availablePorts = [5000, 5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009, 5010]
        let availablePortIndex = availablePorts.indexOf(initialPortNumber)

        return of(availablePorts).pipe(
          mergeMap((availablePorts) => {
            const rpc = new GrpcWebImpl(`http://localhost:${availablePorts[availablePortIndex]}`, {})
            const client = new SaladBowlServiceClientImpl(rpc)
            return defer(() => client.login({ jwt: jwtResponse })).pipe(
              map((loginResponse) =>
                loginResponse.success
                  ? connectedToSaladBowl({ connected: true, isPasskeyLogin })
                  : connectedToSaladBowl({ connected: false, isPasskeyLogin }),
              ),
              retry({
                delay: (error, retryCount) => {
                  if (retryCount >= 2 || (error instanceof Response && error.status === 400)) {
                    throw error
                  }

                  return timer(Math.min(Math.pow(2, retryCount) * 1000 + Math.floor(Math.random() * 1000), 30000))
                },
              }),
            )
          }),
          catchError((error) =>
            concat(
              of(setPage(LoginPageEnum.STILL_CONNECTING_TO_SALAD)),
              throwError(() => error),
            ),
          ),
          tap({
            error: () => {
              availablePortIndex <= availablePorts.length ? ++availablePortIndex : (availablePortIndex = 0)
            },
          }),
          retry(),
          startWith(setPage(LoginPageEnum.CONNECTING_TO_SALAD)),
        )
      }),
      catchError(() => of(connectedToSaladBowl({ connected: false }))),
    ),
  )
