import { defineMessages } from 'react-intl'

export const OnboardingEnterReferralCodePageMessages = defineMessages({
  OnboardingEnterReferralCodePageTitle: {
    id: 'login.onboardingEnterReferralCodePage.title',
    defaultMessage: 'Enter Your Referral Code',
  },
  OnboardingEnterReferralCodePagePrimaryDescription: {
    id: 'login.onboardingEnterReferralCodePage.primaryDescription',
    defaultMessage: 'If you received a referral code, enter it below to boost your earning rate.',
  },
  OnboardingEnterReferralCodePageInputTitle: {
    id: 'login.onboardingEnterReferralCodePage.inputTitle',
    defaultMessage: 'Promo Code',
  },
  OnboardingEnterReferralCodePageInputPlaceHolder: {
    id: 'login.onboardingEnterReferralCodePage.inputPlaceHolder',
    defaultMessage: 'Enter Your Code',
  },
  OnboardingEnterReferralCodePageSubmitButtonLabel: {
    id: 'login.onboardingEnterReferralCodePage.submitButtonLabel',
    defaultMessage: 'Submit',
  },
  OnboardingEnterReferralCodePageSkipButtonLabel: {
    id: 'login.onboardingEnterReferralCodePage.skipButtonLabel',
    defaultMessage: 'Skip',
  },
  OnboardingEnterReferralCodePageImageAltText: {
    id: 'login.onboardingEnterReferralCodePage.imageAltText',
    defaultMessage: 'Referrals',
  },
})
