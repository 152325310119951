import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { apiUrl } from '../../config'
import { ErrorMessages } from '../../messages'
import { ExternalAuthProviderLoginStatus } from '../../models'
import './googleSignInForm.css'

interface GoogleSignInProps {
  isTermsAccepted: boolean
}

export const GoogleSignInForm: FunctionComponent<GoogleSignInProps> = ({ isTermsAccepted }) => {
  const [isError, setIsError] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    if (isTermsAccepted) {
      return setIsError(false)
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const externalLoginStatus = urlSearchParams.get('external-login-status')

    if (externalLoginStatus === ExternalAuthProviderLoginStatus.Failed) {
      setIsError(true)
    }
  }, [isTermsAccepted])

  const handleSubmit = () => {
    if (window.location.search) {
      localStorage.setItem('preserved_search_params', window.location.search)
    }
  }

  return (
    <form onSubmit={handleSubmit} action={`${apiUrl}/api/v2/authentication/external`} method="POST">
      <input type="hidden" name="provider" value="google" />
      <input type="hidden" name="termsAccepted" value={`${isTermsAccepted}`} />
      <button
        className={classNames('p-0 m-0 google-signin-button', {
          'google-signin-button-enabled': isTermsAccepted,
          'google-signin-button-disabled': !isTermsAccepted,
        })}
        type="submit"
        disabled={!isTermsAccepted}
      />
      {isError && (
        <p className="mt-1 p-2 has-text-primary help has-background-danger is-danger">
          {intl.formatMessage(ErrorMessages.errorMessageAccountConnectionFailed)}
        </p>
      )}
    </form>
  )
}
