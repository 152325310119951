import { Observable } from 'rxjs'

/**
 * Creates an observable that wraps a `fetch` request.
 * @param fetch The `fetch` function used to make the request.
 * @param input The URL or `Request` object.
 * @param init The request options.
 * @returns The observable.
 */
export const fetchAsObservable = (
  fetch: (typeof window)['fetch'],
  input: RequestInfo,
  init?: RequestInit,
): Observable<Response> =>
  new Observable((observer) => {
    let controller: AbortController | undefined
    if (window.AbortController) {
      controller = new AbortController()
      init =
        init == null
          ? {
              signal: controller.signal,
            }
          : {
              ...init,
              signal: controller.signal,
            }
    }

    // This is set on resolve, reject, or cancel (when unsubscribing from observable).
    let completed = false
    fetch(input, {
      ...init,
      headers: {
        'X-XSRF-TOKEN': '1',
      },
    })
      .then((response) => {
        if (!completed) {
          completed = true

          if (response.ok) {
            observer.next(response)
            observer.complete()
          } else {
            observer.error(response)
          }
        }
      })
      .catch((error) => {
        if (!completed) {
          completed = true

          observer.error(error)
        }
      })

    return () => {
      if (!completed) {
        completed = true

        if (controller) {
          controller.abort()
        }
      }
    }
  })

/**
 * Creates a `Blob` object of JSON-encoded data that may be used in the body of a `fetch` request.
 *
 * @param data The data to JSON encode.
 * @returns The `Blob` object.
 */
export const json = (data: unknown) => new Blob([JSON.stringify(data)], { type: 'application/json' })

export const isProblemDetail = (data: unknown): data is { type: string; [key: string]: unknown } => {
  if (typeof data === 'object' && data != null) {
    const obj = data as Record<string, unknown>
    if (typeof obj.type === 'string') {
      return true
    }
  }
  return false
}
