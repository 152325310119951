import { defineMessages } from 'react-intl'

export const LoginCompletedPageMessages = defineMessages({
  LoginCompletedPageTitle: {
    id: 'login.loginCompletedPage.title',
    defaultMessage: 'Great Job Chef!',
  },
  LoginCompletedPageDescription: {
    id: 'login.loginCompletedPage.description',
    defaultMessage: 'Salad is running on your PC. Open it by clicking the Salad tray icon. ',
  },
  LoginCompletedPageImageAltText: {
    id: 'login.loginCompletedPage.imageAltText',
    defaultMessage: 'Rewards',
  },
})
