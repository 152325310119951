import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { LoginPageEnum } from '../models'

export interface ApiState {
  pageStep: LoginPageEnum
  loginEmail?: string
  loginCode?: string
  referralCode?: string
  referralCodeSubmitLoading: boolean
  referralCodeErrorMessage?: string
  loginEmailSubmitLoading: boolean
  loginEmailErrorMessage?: string
  loginCodeSubmitLoading: boolean
  loginCodeErrorMessage?: string
  isAuthenticated: boolean
  isPasskeyLoginFailed: boolean
}

export const initialState: ApiState = {
  pageStep: LoginPageEnum.ENTER_EMAIL,
  loginEmailSubmitLoading: false,
  loginCodeSubmitLoading: false,
  referralCodeSubmitLoading: false,
  isAuthenticated: false,
  isPasskeyLoginFailed: false,
}

export const executeUpdateViewedReferralOnboardingAndRedirect = createAction(
  'app/executeUpdateViewedReferralOnboardingAndRedirect',
)
export const loginWithPasskey = createAction('app/loginWithPasskey')
export const resetSSOLogin = createAction('app/resetSSOLogin')

export const slice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<LoginPageEnum>) => {
      state.pageStep = action.payload
    },
    setLoginEmail: (state, action: PayloadAction<string | undefined>) => {
      state.loginEmail = action.payload
    },
    setLoginEmailSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.loginEmailSubmitLoading = action.payload
    },
    setLoginEmailErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.loginEmailErrorMessage = action.payload
    },
    setLoginCode: (state, action: PayloadAction<string>) => {
      state.loginCode = action.payload
    },
    setResendLoginCode: (state, action: PayloadAction<string>) => {
      state.loginEmail = action.payload
    },
    setLoginCodeErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.loginCodeErrorMessage = action.payload
    },
    setLoginCodeSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.loginCodeSubmitLoading = action.payload
    },
    setReferralCode: (state, action: PayloadAction<{ code: string; track?: boolean }>) => {
      state.referralCode = action.payload.code
    },
    setReferralCodeSubmitLoading: (state, action: PayloadAction<boolean>) => {
      state.referralCodeSubmitLoading = action.payload
    },
    setReferralCodeErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.referralCodeErrorMessage = action.payload
    },
    executeRedirect: (_state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined) {
        window.location.href = `${action.payload}`
      }
    },
    setAuthenticatedStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setIsPasskeyLoginFailed: (state, action: PayloadAction<boolean>) => {
      state.isPasskeyLoginFailed = action.payload
    },
  },
})

export const {
  setLoginCode,
  setLoginCodeErrorMessage,
  setLoginCodeSubmitLoading,
  setResendLoginCode,
  setLoginEmail,
  setLoginEmailErrorMessage,
  setLoginEmailSubmitLoading,
  setPage,
  setReferralCode,
  setReferralCodeSubmitLoading,
  setReferralCodeErrorMessage,
  executeRedirect,
  setAuthenticatedStatus,
  setIsPasskeyLoginFailed,
} = slice.actions
