import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import '../animations.css'
import HorizontalRewardBanner from '../assets/HorizontalRewardBanner.png'
import { FetchingIngredientsPageMessages } from '../messages/FetchingIngredientsPageMessages'

interface FetchingIngredientsPageProps {}

export const FetchingIngredientsPage: FunctionComponent<FetchingIngredientsPageProps> = () => {
  const intl = useIntl()

  return (
    <div className="container max-height-100vh pt-6 has-text-centered is-clipped">
      <h1 className="title title-size pt-6 ellipsis-loading">
        {intl.formatMessage(FetchingIngredientsPageMessages.fetchingIngredientsPageTitle)}
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </h1>
      <figure className="image">
        <img
          src={HorizontalRewardBanner}
          alt={intl.formatMessage(FetchingIngredientsPageMessages.fetchingIngredientsPageImageAltText)}
        />
      </figure>
    </div>
  )
}
