import { defineMessages } from 'react-intl'

export const StillConnectingToSaladPageMessages = defineMessages({
  stillConnectingToSaladPageTitle: {
    id: 'login.stillConnectingToSaladPage.title',
    defaultMessage: 'Still connecting to Salad',
  },
  stillConnectingToSaladPageDescription: {
    id: 'login.stillConnectingToSaladPage.description',
    defaultMessage: `We're having trouble connecting to Salad services. Please wait a few moments while we retry. View this {guide} for help if the issue persists.`,
  },
  stillConnectingToSaladPageImageAltText: {
    id: 'login.stillConnectingToSaladPage.imageAltText',
    defaultMessage: 'Rewards',
  },
  stillConnectingToSaladPageGuideCopy: {
    id: 'login.stillConnectingToSaladPage.guideCopy',
    defaultMessage: 'guide',
  },
})
